<script>
import moment from 'moment'

export default {
  name: 'RemainingDays',
  props: {
    endDate: { required: false, type: String },
    startDate: { required: false, type: String },
    context: { required: false, type: String, default: 'card' }
  },
  computed: {
    dateFormatter () {
      if (this.endDate) {
        return new Date(this.endDate).toLocaleString(this.$i18n.locale + '-CA', { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' })
      } else if (this.startDate) {
        return new Date(this.startDate).toLocaleString(this.$i18n.locale + '-CA', { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' })
      } else {
        return null
      }
    },
    getColorFromRemainingDays () {
      if (this.remainingDays) {
        if (this.remainingDays > 10) {
          return 'success'
        } else if (this.remainingDays < 10 && this.remainingDays > 5) {
          return 'orangered'
        } else {
          return 'red'
        }
      } else {
        return null
      }
    },
    remainingDays () {
      const now = moment()
      const to = moment(this.endDate ? this.endDate : this.startDate)
      const remaining = now.diff(to, 'days')
      if (remaining === 0) {
        return 1
      } else {
        return -remaining
      }
    }
  }
}
</script>
<template lang="pug">
div
  div.align-days-presentation(v-if='context === "presentation"')
    span.days-presentation-chiffre(v-if='remainingDays <= 30') {{Math.abs(remainingDays)}}
    span.days-presentation(v-if='remainingDays >= 0 && remainingDays <= 30') {{$tc('partage.jours-restants', remainingDays) }}
    span.days-presentation(v-else-if='remainingDays < 0') {{ $t('calendrier.expire-depuis') }} {{$tc('partage.jours-retards', Math.abs(remainingDays)) }}
    //- p.days-presentation(v-if="remainingDays <= 1") jour restant
    //- p.days-presentation(v-else) jours restants
  v-chip(v-else-if='context === "card"', small, :color='getColorFromRemainingDays', outlined, style='background-color:white !important;')
    div.flex.items-center
      font-awesome-icon.f5.mr2(:icon="['fad', 'calendar-alt']")
      span(v-if='remainingDays < 0') {{ $t('calendrier.expire-depuis') }} &nbsp;
      span {{new Date(endDate).toLocaleString('fr-FR', { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' })}} &nbsp;
  div.f7.mt2(v-else-if='context === "cahier"')
    template(v-if='endDate')
      span(v-if='remainingDays >= 1 && remainingDays <= 30') {{ $t('messages.accessible-durant', { remainingDays }) }}
      span(v-else-if='remainingDays > 30') {{ $t('messages.accessible-jusque') }} {{new Date(endDate).toLocaleString($i18n.locale + '-CA', { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' })}}
      span(v-else) {{ $t('calendrier.expire') }}
    template(v-else-if='startDate')
      span {{ $t('messages.accessible-partir') }} {{new Date(startDate).toLocaleString($i18n.locale + '-CA', { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' })}}
  v-chip.primary.lighten-4.secondary--text(v-else, small, outlined, style='background-color:white !important;')
    div.flex.items-center
      font-awesome-icon.f5.mr2(:icon="['fad', 'calendar-alt']")
      span {{ new Date(endDate).toLocaleString($i18n.locale + '-CA', { day: 'numeric', month: 'short', year: 'numeric' })}}
</template>

<style lang='sass'>
.days-presentation-chiffre
  font-size: 72px
  font-weight: $bold
  font-family: $display-font
  color: white
.days-presentation
  font-size: 18px
  font-weight: $bold
  font-family: $display-font
  color: white
.align-days-presentation
  display: flex
  flex-direction: column
  align-content: center
  align-items: center

@media all and (max-width: $large)
  .days-presentation-chiffre
    font-size: 48px
  .days-presentation
    font-size: 12px

</style>
