<script>
import RemainingDays from '@/components/shared/remainingDays.vue'
import moment from 'moment'
export default {
  name: 'BibliothequeProduit',
  components: {
    RemainingDays
  },
  data () {
    return {
      loading: true,
      produit: null,
      expand: false
    }
  },
  computed: {
    noMoreDistribuable () {
      let temp = false
      let validator = this.nuage && this.nuage.distribution && this.nuage.distribution.find(e => e.role.toLowerCase().includes('eleve')) && (this.nuage.distribution.find(e => e.role.toLowerCase().includes('eleve')).total === 0)
      if (validator) {
        temp = true
      }
      return temp
    },
    isExpired () {
      let temp = false
      // produit.nuageItem.finAt
      if (this.produit && this.produit.nuageItem && this.produit.nuageItem.finAt) {
        const now = moment()
        const to = moment(this.produit.nuageItem.finAt)
        const remaining = now.diff(to, 'days')
        if (-remaining < 0) {
          temp = true
        }
      }
      return temp
    },
    format () {
      let temp = this.$t('lecture.cahier-numerique')
      if (this.produit && this.produit.boutiqueItem && this.produit.boutiqueItem.formats && this.produit.boutiqueItem.formats.length > 0) {
        temp = this.produit.formats[0].titre
      } else if (this.produit && this.produit.formats && this.produit.formats.length > 0) {
        temp = this.produit.formats[0].titre
      }
      return temp
    },
    couverture () {
      if (this.produit && this.produit.image && this.produit.image.medium_image) {
        return { couverture: 'https://plus.dokoma.com' + this.produit.image.medium_image, thumbnail: 'https://plus.dokoma.com' + this.produit.image.thumbnail }
      } else if (this.produit && this.produit.images && this.produit.images.length > 0) {
        const couv = this.produit.images.find(e => e.tagsVisibles.find(t => t.id === 'details-graphiques_image-couverture'))
        if (couv && couv.imageUrl) {
          return { couverture: couv.imageUrl, thumbnail: couv.imageUrl }
        } else {
          return null
        }
      }
      return null
    },
    imagesList () {
      let images = {}
      if (this.produit && this.produit.images && this.produit.images.length) {
        this.produit.images.forEach(img => {
          images.type = 'sku'
          if (img.tagsVisibles && img.tagsVisibles.find(tag => tag.id === 'details-graphiques_image-couverture')) {
            images.coverImage = img
          } else if (img.tagsVisibles && img.tagsVisibles.find(tag => tag.id === 'details-graphiques_image-pictogramme')) {
            images.pictogramme = img
          }
        })
      } else if (this.produit) {
        images.type = 'produit'
        if (this.produit.image) {
          images.coverImage = this.produit.image
        }
        if (this.produit.image_picto) {
          images.pictogramme = this.produit.image_picto
        }
      }
      return images
    }
  },
  apollo: {
    nuageItem: {
      query: require('@/graphql/queries/v2/nuageItem.gql'),
      variables () {
        return {
          id: this.nuage.id,
          page: this.pageNuage,
          limit: 12
        }
      },
      update (data) {
        return data.nuageItem
      },
      fetchPolicy: 'network-only',
      skip () {
        return !this.nuage || !this.nuage.id
      }
    },
    produit: {
      query: require('@/graphql/queries/v2/boutiqueItemBy.gql'),
      variables () {
        return {
          attrs: { slug: this.$route.params.id }
        }
      },
      update (data) {
        this.loading = false
        return data.boutiqueItemBy
      },
      watchLoading (isLoading) {
        this.loading = isLoading
      }
    }
  },
  methods: {
    getFontSize (mot) {
      if (mot.length <= 10) {
        return 2 + 'em'
      } else if (mot.length > 10 && mot.length <= 20) {
        return 2 + 'em'
      } else if (mot.length > 20 && mot.length <= 40) {
        return 2 + 'em'
      } else if (mot.length > 30 && mot.length <= 50) {
        return 2 + 'em'
      } else {
        return 1.75 + 'em'
      }
    }
  }
}
</script>

<template lang="pug">
.produit-index.split-page
  v-sheet.page-header(color='secondary')
    .header-image
      template(v-if='loading')
      template(v-else)
        v-img.w-100.h-100(v-if='imagesList && imagesList.type === "sku" && imagesList.coverImage', :src='imagesList.coverImage.large_image', :lazy-src='imagesList.coverImage.thumbnail')
        v-img.w-100.h-100(v-else-if='imagesList && imagesList.type === "produit" && imagesList.coverImage', :src='imagesList.coverImage.image_type === "Svg" ? imagesList.coverImage.medium_image : "https://plus.dokoma.com" + imagesList.coverImage.large_image', :lazy-src='imagesList.coverImage.image_type === "Svg" ? imagesList.coverImage.medium_image : "https://plus.dokoma.com" + imagesList.coverImage.large_image')
    .header__inner-content
      template(v-if='loading')
      template(v-else)
        div.flex.justify-between.items-center.w-100.mb3
          v-btn.white--text(:small='$store.state.App.mobileTemplate', :to="{ name: 'bibliotheque___' + $i18n.locale }", rounded, color='primary')
            font-awesome-icon.mr2.f4(:icon="['fas', 'arrow-left']")
            span {{$t('menu.mes-cahiers')}}
          div.flexjours
            remaining-days(v-if='produit && produit.nuageItem && produit.nuageItem.finAt && !isExpired', :endDate='produit.nuageItem.finAt', context='presentation')
        div.flex-grow-1
        template
          v-avatar.mb1.relative(v-if='imagesList && imagesList.pictogramme', size='45', color='transparent')
            div.color-fallback()
            v-img(v-if='imagesList.type === "sku"', :src='imagesList.pictogramme.thumbnail')
            v-img(v-else-if='imagesList.type === "produit"', :src='imagesList.pictogramme.image_type === "Svg" ? imagesList.pictogramme.url_to_image : "https://plus.dokoma.com" + imagesList.pictogramme.url_to_image')
          .header-title()
            div.secondary-title {{format}}
            div.main-title(:style="{ fontSize: getFontSize(produit.titre)}") {{produit.titre}}
            .header__extra-content
              remaining-days(v-if='produit.nuageItem && produit.nuageItem.finAt', :endDate='produit.nuageItem.finAt', context='card')
              //- v-chip.primary.lighten-5.secondary--text.mt1(small)
                font-awesome-icon.f6.mr2(:icon="['fad', 'file-edit']")
                span {{ $tc('lecture.nombre-page', $store.state.Bibliotheque.nombrePageDocument, {page: $store.state.Bibliotheque.nombrePageDocument}) }}
  .page-actions
  .page__main-content.mt3.animated.fadeIn
    v-skeleton-loader(v-if='loading', type='heading')
    section.fullsize-section(v-else-if='produit && (produit.descriptionNuage || (produit.public && produit.descriptionPublic))')
      div.relative.mb3
        v-expand-transition
          v-card.transparent(v-show='expand', height='100%', width='100%', flat)
            template(v-if='produit.descriptionNuage || produit.descriptionPublic')
              div.produit-description(v-if='produit.public && produit.descriptionPublic', v-html='produit.descriptionPublic')
              div.produit-description(v-else-if='produit.descriptionNuage', v-html='produit.descriptionNuage')
            template(v-else-if='produit.description')
              .produit-description(v-html='produit.description')
        v-btn(@click='expand = !expand', text, block, small, rounded ,outlined)
          | {{expand ? $t('action.masque-description') : $t('action.affiche-description') }}
          font-awesome-icon.ml2(:icon="['fas', expand ? 'caret-up' : 'caret-down']", style='font-size: 18px')
    section.small-section
      .section-subtitle__header
        h2.section-subtitle {{$t('produit.attribution')}}&nbsp;
        v-btn(v-if='!noMoreDistribuable', small, outlined, rounded, @click.native="openEleveSelection", :disabled='noMoreDistribuable || isExpired') {{$t('action.gestion-credit')}}
      v-sheet.section-card
        v-skeleton-loader.small-section(v-if='loading', type='list-item-three-line')
        v-expansion-panels(light, accordion)
        //- v-list
        //-   v-list-item(v-for='enseignant in 5', :key='enseignant')
        //-     v-list-item-content
        //-       v-list-item-title nom du credit
        div.empty-content__wrapper.pa4(v-if='!noMoreDistribuable')
          div {{$t('messages.docu-non-attri')}}
          v-btn.mt3(rounded, color='primary', @click='showElevesSelection = !showElevesSelection', :disabled='isExpired') {{$t('action.ajout-eleves')}}
</template>

<style lang='sass'>
  @import 'src/styles/components/bibliotheque/_produit'
  .produit-index.split-page
    .page-actions
      margin: 10px
  .description-lecture
    padding: 30px
    background-color: white
    border-radius: 10px
  .section-card.groupes
    .v-expansion-panels
      .v-expansion-panel
        &:not(:last-of-type)
          border-bottom: solid 1px white
      .v-expansion-panel-content
        .v-expansion-panel-content__wrap
          padding: 0
  .produit-banner
    .v-image
      .v-responsive__content
        width: unset !important
  .flexjours
    display: flex
    flex-direction: row
    align-content: flex-end
</style>
